<template>
  <div class="w-full bg-white">
    <div class="w-full px-[20px]">
      <div class="py-[10px]">
        <label class="text-[15px] font-semibold">{{ $t('git.create_as') }}</label>
        <LittleTabs
            :tabs="tabs"
            :switch-tabs="switchTabs"
            :button-size="'sm'"
            containerStyle="justify-content-start"
        />
      </div>
      <div class="w-full py-[10px]">
        <div class="flex">
          <div class="w-[30%]">
            <div class="w-full lg:w-[80%] py-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.invoice_fee') }}</label>
              <b-form-input type="text"
                            :disabled="true"
                            :value="invoice.invoice_fee"
                            class="w-[40%]"
                            :placeholder="$t('git.invoice_fee')"
              />
            </div>
            <div class="w-full lg:w-[80%] pb-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.due_date') }}</label>
              <b-form-input type="text"
                            :disabled="true"
                            :value="invoice.due_date"
                            class="w-[40%] rounded-none"
                            :placeholder="$t('git.due_date')"
              />
            </div>
            <div class="w-full lg:w-[80%] pb-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.invoice_date') }}</label>
              <b-form-input type="text"
                            :disabled="true"
                            :value="invoice.invoice_date"
                            class="w-[40%] rounded-none"
                            :placeholder="$t('git.invoice_date')"
              />
            </div>
            <div class="w-full lg:w-[80%] pb-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.your_reference') }}</label>
              <b-form-input type="text"
                            :disabled="true"
                            :value="invoice.your_reference"
                            class="w-[40%] rounded-none"
                            :placeholder="$t('git.your_reference')"
              />
            </div>
            <div class="w-full lg:w-[80%] pb-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.our_reference') }}</label>
              <b-form-input type="text"
                            :disabled="true"
                            :value="invoice.our_reference"
                            class="w-[40%] rounded-none"
                            :placeholder="$t('git.our_reference')"
              />
            </div>
          </div>
          <div class="w-[60%]">
            <div class="w-full lg:w-[70%] py-[10px]">
              <label class="text-[15px] font-semibold">{{ $t('git.invoice_text') }}</label>
              <b-form-textarea
                  id="textarea"
                  :disabled="true"
                  v-model="invoice.invoice_text"
                  :placeholder="$t('git.invoice_text')"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="w-full">
              <div class="grid grid-cols-2 gap-2">
                <div class="w-full lg:w-[80%] pb-[10px]">
                  <label class="text-[15px] font-semibold">{{ $t('git.extra_invoice_row_text') }}</label>
                  <b-form-input type="text"
                                :disabled="true"
                                :value="invoice.extra_invoice_row_text"
                                class="w-[40%] rounded-none"
                                :placeholder="$t('git.extra_invoice_row_text')"
                  />
                </div>
                <div class="w-full lg:w-[80%] pb-[10px]">
                  <label class="text-[15px] font-semibold">{{ $t('git.contract_length') }}</label>
                  <b-form-input type="text"
                                :disabled="true"
                                :value="invoice.contract_length"
                                class="w-[40%] rounded-none"
                                :placeholder="$t('git.contract_length')"
                  />
                </div>
                <div class="w-full lg:w-[80%] pb-[10px]">
                  <label class="text-[15px] font-semibold">{{ $t('git.payment_terms') }}</label>
                  <b-form-input type="text"
                                :disabled="true"
                                :value="invoice.terms_of_payment_description"
                                class="w-[40%] rounded-none"
                                :placeholder="$t('git.payment_terms')"
                  />
                </div>
                <div class="w-full lg:w-[80%] pb-[10px]">
                  <label class="text-[15px] font-semibold">{{ $t('git.invoice_interval') }}</label>
                  <b-form-input type="text"
                                :disabled="true"
                                :value="invoice.invoice_interval"
                                class="w-[40%] rounded-none"
                                :placeholder="$t('git.invoice_interval')"
                  />
                </div>
                <div class="w-full lg:w-[80%] pb-[10px]">
                  <label class="text-[15px] font-semibold">{{ $t('git.divide_amount_on_contract_length') }}</label>
                  <b-form-checkbox :disabled="true"
                                   :value="invoice.divide_amount_on_contract_length"
                                   class="custom-control-primary my-auto"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceSettings",
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      tabs: [
        {label: 'git.invoices', active: this.invoice.create_contracts === false},
        {label: 'git.contracts', active: this.invoice.create_contracts === true},
      ]
    }
  },
  methods: {
    switchTabs(tabIndex) {
    },
  },
  mounted() {
    if (this.invoice.create_contracts === false) {
      this.tabs[0].active = true
      this.tabs[1].active = false
    } else {
      this.tabs[0].active = false
      this.tabs[1].active = true
    }
  }
}
</script>

<style scoped>

</style>